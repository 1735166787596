import React from 'react'

function ContactPopUp({closePopUp, select, unselect, copy}) {
  return (
    <div className='popup-bg'>
      <div className='popup-box'>
        <div className='close-popup' onMouseEnter={select} onMouseLeave={unselect} onClick={closePopUp}><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg></div>
        <h3>Let's create something <span className='primary'>together!</span></h3>
        <div className='popup-email-cont' onMouseEnter={select} onMouseLeave={unselect} onClick={copy}>
          <div className='popup-email'>
            adribyme@gmail.com
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" /><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" /></svg>
        </div>
      </div>
    </div>
  )
}

export default ContactPopUp