import React from 'react'

function ProjectItem({image, name, desc, url, hoverMouseEnter, hoverMouseExit}) {

  const openProject = () => {
    window.open(url);
  };

  return (
    <div className='p-box' onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit} onClick={openProject}>
      <div className='p-image'><img src={image} alt="Porject Thumbnail"/></div>
      <div className='p-image-bg'></div>
      <div className='p-info-cont'>
        <div className="p-info-cont-left">
          <h3 className='t40'>{name}</h3>
          <p>{desc}</p>
        </div>
        <div className="p-info-cont-right">
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width="35" height="35" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg>
        </div>
      </div>
    </div>
  )
}

export default ProjectItem