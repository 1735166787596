import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import QuipQuickInfo from './Pages/QuipQuickInfo';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import ShortMyInfo from './Pages/ShortMyInfo';
import SpeedCoding from './Pages/SpeedCoding';

const esTranslation = require('./Lang/es.json');
const enTranslation = require('./Lang/en.json');

i18n.init({
  interpolation: {escapeValue: false},
  lng: 'es',
  resources: {
    en: {
      translation: enTranslation.translation,
    },
    es: {
      translation: esTranslation.translation,
    }
  }
});

const savedLanguage = localStorage.getItem('language');
if (savedLanguage) {
  i18n.changeLanguage(savedLanguage);
} else {
  const userLang = navigator.language || navigator.userLanguage;

  i18n.changeLanguage(userLang);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} index />
          <Route path="/quip-quick" element={<QuipQuickInfo />} />
          <Route path="/shortmy" element={<ShortMyInfo />} />
          <Route path="/speed-coding" element={<SpeedCoding />} />
        </Routes>
      </Router>
    </HelmetProvider>
  </I18nextProvider>
);
