import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function MainTopMenu({hoverMouseEnter, hoverMouseExit}) {
  const {t} = useTranslation();

  const [isMobile, setIsMobile] = useState(false);

  const [actualLang, setActualLang] = useState(i18n.language);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 1100);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    setActualLang(lang);
  }

  return (
  <div className='main-menu'>
    {isMobile ? <div className='menu-content'>
      <ul>
        <li onClick={() => window.location.href = "#projects"} onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit}>{t("menuItem1")}</li>
        <li onClick={() => window.location.href = "#studies"} onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit}>{t("menuItem2")}</li>
        {/*<li onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit}>{t("menuItem3")}</li>*/}
        <li onClick={() => window.location.href = "#contact"} onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit}>{t("menuItem4")}</li>
      </ul>
      <div className='lang-selector'>
        <button onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit} onClick={() => changeLang('en')} className={actualLang === 'en' || actualLang === 'en-EN' ? 'lang-selected': ''}>En</button>
        <div className='devider'></div>
        <button onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit} onClick={() => changeLang('es')} className={actualLang === 'es' || actualLang === 'es-ES' ? 'lang-selected': ''}>Es</button>
      </div>
    </div>: 
    <div className='menu-content'>
      <div className='lang-selector'>
        <button onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit} onClick={() => changeLang('en')} className={actualLang === 'en' || actualLang === 'en-EN' ? 'lang-selected': ''}>En</button>
        <div className='devider'></div>
        <button onMouseEnter={hoverMouseEnter} onMouseLeave={hoverMouseExit} onClick={() => changeLang('es')} className={actualLang === 'es' || actualLang === 'es-Es' ? 'lang-selected': ''}>Es</button>
      </div>
    </div>
    }
    
  </div>

  )
}

export default MainTopMenu