import React, { useEffect, useState } from 'react';
import { Timeline } from 'flowbite-react';
import copy from 'clipboard-copy';
import { Toaster, toast} from 'sonner';
import { useTranslation } from 'react-i18next';
import TagCloud from 'TagCloud';

import MainTopMenu from './Components/MainTopMenu';
import ProjectItem from './Components/ProjectItem';

//const tags = ['React', 'JavaScript', 'Web Development', 'Tag Cloud', 'CSS'];

const container = '.content';
const texts = [
  'React', 'React native', 'JavaScript',
  'CSS3', 'HTML', 'Node JS',
  'C#', 'Python', 'Unity',
  'Unreal Engine', '3D',
  'Solidity', 'MySQL',
];
const options = {
  radius: 300,
  maxSpeed: 'normal',
  initSpeed: 'normal',
  direction: 135,
  keep: true
};

function App() {
  const { t } = useTranslation();

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = e.pageX;
      const y = e.pageY;
      setCursorPosition({ x, y });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  useEffect(() => {
    const content = document.querySelector(".content");
    content.innerHTML = '';
    TagCloud(container, texts, options);
  }, []);


  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };

  const copyEmail = async () => {
    try {
      await copy("adribyme@gmail.com");
      toast(t("emailCopyText"));
    } catch (e) {

    }
  };

  return (
    <div className="app normal">
      <div
        className="cursor main-cursor"
        style={{
          transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
        }}
      ></div>
      <div
        className="cursor following-cursor"
        style={{
          transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
        }}
      ></div>
      {hovered && (
        <div
          className="cursor following-cursor-large"
          style={{
            transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
          }}
        ></div>
      )}
      <MainTopMenu hoverMouseEnter={handleHover} hoverMouseExit={handleLeave}/>
      <div className='email-cont' onMouseEnter={handleHover} onMouseLeave={handleLeave} onClick={copyEmail}>
        adribyme@gmail.com
      </div>
      <div className='home-content'>
        <div className='home-center-text'>
          <h1 id="home-title">{t("homePageTitle")}</h1>
          <div className='slice'></div>
          <h3 id="home-subtitle">{t("homePageSubtitle")}</h3>
        </div>
        <div className='slide-down'>
          <div className='slide-down-ball'></div>
          <p>{t("slide")}</p>
        </div>
      </div>
      <div className='projects-content' id="projects">
        <div className='t40' style={{fontSize: "20px", letterSpacing: "15px"}}>
          <h2>{t("menuItem1")}</h2>
        </div>
        {/*<div className='full-width-line'></div>*/}
        <div className='projects-box'>
          <ProjectItem image="https://i.imgur.com/PhMZwV6.jpg" name={t("quipQuickTitle")} desc={t("quipQuickShortDesc")} url="quip-quick" hoverMouseEnter={handleHover} hoverMouseExit={handleLeave}/>
          <ProjectItem image="https://i.imgur.com/RxhaTpX.jpg" name={t("shortMyTitle")} desc={t("shortMyDesc")} url="shortmy" hoverMouseEnter={handleHover} hoverMouseExit={handleLeave}/>
          <ProjectItem image="https://i.imgur.com/j2IYqms.jpg" name={t("speedCodingTitle")} desc={t("speedCodingDesc")} url="speed-coding" hoverMouseEnter={handleHover} hoverMouseExit={handleLeave}/>
        </div>
      </div>
      <div className='studies-content t100' id="studies">
        <div className='t40 white' style={{fontSize: "20px", letterSpacing: "15px"}}>
          <h2>{t("menuItem2")}</h2>
        </div>
        <div className='studies-timeline t40'>
        <Timeline style={{maxWidth: "900px"}}>
            <Timeline.Item>
              <Timeline.Point />
              <Timeline.Content>
                <Timeline.Time>{t("dateFirstStudy")}</Timeline.Time>
                <Timeline.Title style={{color: "white"}}>{t("titleFirstStudy")}</Timeline.Title>
                <Timeline.Body style={{color: "#afafaf"}}>
                {t("descFirstStudy")}
                </Timeline.Body>
              </Timeline.Content>
            </Timeline.Item>
            <Timeline.Item>
              <Timeline.Point />
              <Timeline.Content>
                <Timeline.Time>{t("dateSecondStudy")}</Timeline.Time>
                <Timeline.Title style={{color: "white"}}>{t("titleSecondStudy")}</Timeline.Title>
                <Timeline.Body style={{color: "#afafaf"}}>
                {t("descSecondStudy")}
                </Timeline.Body>
              </Timeline.Content>
            </Timeline.Item>
            <Timeline.Item>
              <Timeline.Point />
              <Timeline.Content>
                <Timeline.Time>{t("dateThirdStudy")}</Timeline.Time>
                <Timeline.Title style={{color: "white"}}>{t("titleThirdStudy")}</Timeline.Title>
                <Timeline.Body style={{color: "#afafaf"}}>
                {t("descThirdStudy")}
                </Timeline.Body>
              </Timeline.Content>
            </Timeline.Item>
          </Timeline>
        </div>
        <span className="content" id="contact"></span>
      </div>
        <div className='contact-content t40'>
        <div className='t40 white' style={{fontSize: "20px", letterSpacing: "15px"}}>
          <h2>{t("menuItem4")}</h2>
        </div>
          <div className='contact-box'>
            <h3 className='white'>{t("contactTitle1")}<span className='primary'>{t("contactTitle2")}</span></h3>
            <div className='popup-email-cont' onMouseEnter={handleHover} onMouseLeave={handleLeave} onClick={copyEmail}>
              <div className='popup-email'>
                adribyme@gmail.com
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" /><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" /></svg>
            </div>
          </div>
      </div>
      <Toaster />
      <div id="noise-grain"></div>
    </div>
  );
}

export default App;
