import React, { useEffect, useState } from 'react';
import ContactPopUp from '../Components/ContactPopUp';
import copy from 'clipboard-copy';
import { Toaster, toast} from 'sonner';
import { useTranslation } from 'react-i18next';


function ShortMyInfo() {

  const {t} = useTranslation();

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);

  const [isContactOpen, setIsContactOpen] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = e.pageX;
      const y = e.pageY;
      setCursorPosition({ x, y });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };

  const openWeb = (url) => {
    window.open(url, "_self");
  };

  const openCloseContact = () => {
    if (isContactOpen) {
      handleLeave();
      setIsContactOpen(false);
    } else {
      setIsContactOpen(true);
    }
  };

  
  const copyEmail = async () => {
    try {
      await copy("adribyme@gmail.com");
      toast('Email copied!');
    } catch (e) {

    }
  };

  return (
    <div className='app normal'>
      <div
        className="cursor main-cursor"
        style={{
          transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
        }}
      ></div>
      <div
        className="cursor following-cursor"
        style={{
          transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
        }}
      ></div>
      {hovered && (
        <div
          className="cursor following-cursor-large"
          style={{
            transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
          }}
        ></div>
      )}
      {isContactOpen && <ContactPopUp closePopUp={openCloseContact} select={handleHover} unselect={handleLeave} copy={copyEmail}/>}
      <div className='projects-menu'>
        <div onMouseEnter={handleHover} onMouseLeave={handleLeave} onClick={() => {openWeb("/")}}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler white" width="34" height="34" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l-2 0l9 -9l9 9l-2 0" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
        </div>
        <div onMouseEnter={handleHover} onMouseLeave={handleLeave} onClick={openCloseContact}>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler white" width="34" height="34" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" /><path d="M10 16h6" /><path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 8h3" /><path d="M4 12h3" /><path d="M4 16h3" /></svg>
        </div>
      </div>
      <div className='project-page-top'>
        <div className='project-page-title'> {/* ------------ DECIR QUE ES RESPONSIVE -------------*/}
          <h1>ShortMy Web</h1>
        </div>
        <div className='project-page-tech'>
          <img src='https://i.imgur.com/KrXvRIZ.png' alt="JS Tech" />
          <img src='https://i.imgur.com/8F90rvl.png' alt="React Tech" />
          <img src='https://i.imgur.com/e2k7Yi6.png' alt="Firebase Tech" />
          <img src='https://i.imgur.com/ggdf3lM.png' alt="MySql Tech" />
        </div>
        <button type="button" className='visit-button' onClick={() => window.open("https://shortmy.vercel.app/")} onMouseEnter={handleHover} onMouseLeave={handleLeave}>VISIT WEB <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-up-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 7l-10 10" /><path d="M8 7l9 0l0 9" /></svg></button>
      </div>
      <div className='project-page-content t40'>
        <div className='project-page-in'>
            <p>ShortMy is a fully functional and <span className='b'>scalable link shortening</span> project, providing <span className='b'>real-time statistics</span> and a <span className='b'>custom QR code generator</span>. It utilizes a backend and employs an SQL database to manage and store user data, including accounts, created links, and associated statistics.</p>
            <p className='t40'>With the <span className='b'>link shortening system</span>, you simply input the link, click 'Shorten,' and within seconds, you receive a shortened link. If the user is registered, it is saved in their account, allowing them to view real-time statistics.</p>
            <div className='project-page-images'>
              <img src="https://i.imgur.com/EmghDTS.jpg" alt="ShortMy Shorten" className='round t40'/>
            </div>
            <p className='t40'>The <span className='b'>customizable QR codes</span> feature allows users to add a logo at the center, change the primary color, alter the design, and even modify the background color. Upon clicking 'Download,' an image with the designed QR code is generated.</p>
            <div className='project-page-images'>
              <img src="https://i.imgur.com/gYYuXF3.jpg" alt="ShortMy Custom QR" className='round t40'/>
            </div>
            <p className='t40'>The <span className='b'>user registration system</span> stores email, encrypted password using bcrypt, salt, username, and creation date. When a user with an active session generates a shortened link, it is saved in their account, providing an overview of all shortened links and statistics for each.</p>
            <div className='project-page-images'>
              <img src="https://i.imgur.com/smVTcav.jpg" alt="ShortMy Register Page" className='round t40'/>
            </div>
            {/*<div className='project-page-more'>
              <div className='project-page-more-projects' onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                SHOW MORE PROJECTS
              </div>
        </div>*/}
        </div>
      </div>
      <div className='contact-content t40'>
        <div className='t40 white' style={{fontSize: "20px", letterSpacing: "15px"}}>
          <h2>{t("menuItem4")}</h2>
        </div>
          <div className='contact-box'>
            <h3 className='white'>{t("contactTitle1")}<span className='primary'>{t("contactTitle2")}</span></h3>
            <div className='popup-email-cont' onMouseEnter={handleHover} onMouseLeave={handleLeave} onClick={copyEmail}>
              <div className='popup-email'>
                adribyme@gmail.com
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" /><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" /></svg>
            </div>
          </div>
      </div>
      <Toaster />
      <div id="noise-grain"></div>
    </div>
  )
}

export default ShortMyInfo